import React, { useEffect, useMemo, useState } from "react";
import { EmployeeCard, HeaderCard, LocationCard, SensorCard } from "./Cards";
import InputBox from "../../Components/InputBox/InputBox";
import Button from "../../Components/Button/Button";
import Modal from "../../Components/Modal/Modal";
import {
  CloseIcon,
  PhoneNumberIcon,
  ProcedureEditIcon,
  UserIcon
} from "../../Assets/assets";
import { isValidArray, isValidObject } from "../../Services/validators";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";
import sensorAssignedFallBackImage from "../../Assets/sensorAssignedFallbackImage.svg";
import NativeSelect from "../../Components/NativeSelect/NativeSelect";
import { search } from "fast-fuzzy";

export default function AssignProcedures(props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [intervalModal, setIntervalModal] = useState({
    show: false,
    type: null
  });
  const [intervalTiming, setIntervalTiming] = useState({
    interval: "",
    period: "",
    from: "",
    to: ""
  });
  const [showLocations, setShowLocations] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [searchKey, setSearchKey] = useState({
    employee: "",
    location: "",
    sensor: ""
  });
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [isValidInterval, setIsValidInterval] = useState({
    interval: true,
    period: true,
    from: true,
    to: true
  });

  const confirmButtonDisabled = useMemo(() => {
    const linkedLocationIds = new Set(
      selectedEmployeeData?.linkedProcedures
        ?.filter((lp) => lp.procedureId === props.data.documentId)
        .map((lp) => lp.locationId)
    );

    const selectedLocationIds = new Set(
      selectedLocations.map((sl) => sl.locationId)
    );

    if (linkedLocationIds.size !== selectedLocationIds.size) {
      return false;
    }

    for (const id of linkedLocationIds) {
      if (!selectedLocationIds.has(id)) {
        return false;
      }
    }

    return true;
  }, [
    selectedEmployeeData?.linkedProcedures,
    props.data.documentId,
    selectedLocations
  ]);

  const employeeSearchResults = useMemo(() => {
    const employeeData = JSON.parse(
      JSON.stringify(
        Object.values(props.employees.data).filter(
          (data) => data.type !== "admin"
        )
      )
    );
    if (isValidArray(employeeData)) {
      if (!searchKey.employee) {
        return employeeData.sort((a, b) => a.name - b.name);
      }
      return search(searchKey.employee, employeeData, {
        keySelector: (item) => `${item.name} ${item.phoneNumber}`,
        threshold: 0.7
      }).sort((a, b) => a.phoneNumber - b.phoneNumber);
    }
  }, [searchKey.employee, props.employees.data]);

  const locationSearchResults = useMemo(() => {
    const locationData = JSON.parse(JSON.stringify(props.hits));

    if (isValidArray(locationData)) {
      if (!searchKey.location) {
        return locationData.sort((a, b) => a.PCTNo - b.PCTNo);
      }
      return search(searchKey.location, locationData, {
        keySelector: (item) => `${item.PCTNo} ${item.location}`,
        threshold: 0.7
      }).sort((a, b) => a.PCTNo - b.PCTNo);
    }
  }, [searchKey.location, props.hits]);

  const sensorSearchResults = useMemo(() => {
    const sensorData = JSON.parse(
      JSON.stringify(Object.values(props.sensors.data))
    );
    if (isValidArray(sensorData)) {
      if (!searchKey.sensor) {
        return sensorData.sort((a, b) => a.name - b.name);
      }
      return search(searchKey.sensor, sensorData, {
        keySelector: (item) => `${item.name}`,
        threshold: 0.7
      }).sort((a, b) => a.name - b.name);
    }
  }, [searchKey.sensor, props.sensors.data]);

  useEffect(() => {
    setSelectedEmployeeId(null);
    setSelectedEmployeeData(null);
    setShowLocations(false);
    setSearchKey({
      employee: "",
      location: "",
      sensor: ""
    });
    setShowEditModal(false);
    setIntervalModal(false);
  }, [props.data?.documentId]);

  useEffect(() => {
    const selectedLocationData = selectedLocations.find((data) => {
      return data?.interval && data?.period && data?.from && data?.to;
    });

    if (
      selectedLocationData?.interval &&
      selectedLocationData?.period &&
      selectedLocationData?.from &&
      selectedLocationData?.to &&
      intervalModal.show
    ) {
      setIntervalTiming({
        interval: parseInt(selectedLocationData?.interval),
        period: selectedLocationData?.period,
        from: selectedLocationData?.from,
        to: selectedLocationData?.to
      });
    }
    if (selectedEmployeeId && props.procedureType === "sensor") {
      const selectedSensorData = Object.values(
        props.sensors.data
      ).filter((data) =>
        data.linkedProcedures?.find(
          (value) => value.procedureId === props.data.documentId
        )
      );
      setIntervalTiming({
        ...intervalTiming,
        interval: selectedSensorData[0]?.linkedProcedures?.find(
          (value) => value.procedureId === props.data.documentId
        )?.interval
      });
    }
    // eslint-disable-next-line
  }, [intervalModal, showEditModal]);

  useEffect(() => {
    if (
      showLocations &&
      (props.procedureType === "employee" ||
        props.procedureType === "logistics") &&
      intervalModal.show
    ) {
      const data = selectedEmployeeData?.linkedProcedures?.[0];
      setIntervalTiming({
        interval: parseInt(data?.interval),
        period: data?.period,
        from: data?.from,
        to: data?.to
      });
    }
  }, [selectedEmployeeData, showLocations, props.procedureType, intervalModal]);

  useEffect(() => {
    if (!selectedEmployeeId) {
      setSelectedEmployeeData(null);
    } else if (props.employees.data[selectedEmployeeId]) {
      setSelectedEmployeeData(props.employees.data[selectedEmployeeId]);
    }
  }, [selectedEmployeeId, props.employees.data]);

  useEffect(() => {
    props.algoliaSearchKey(searchKey.location);
    // eslint-disable-next-line
  }, [searchKey.location]);

  useEffect(() => {
    if (
      selectedEmployeeId &&
      selectedEmployeeData?.linkedProcedures?.length > 0
    ) {
      setSelectedLocations([
        ...selectedEmployeeData.linkedProcedures.filter(
          (linkedProcedure) =>
            linkedProcedure.procedureId === props.data.documentId
        )
      ]);
    } else {
      setSelectedLocations([]);
    }
    // eslint-disable-next-line
  }, [
    props.employees.data,
    selectedEmployeeData?.linkedProcedures,
    selectedEmployeeId
  ]);

  useEffect(() => {
    if (showEditModal && !selectedEmployeeId) {
      setShowEditModal(false);
    }
  }, [showEditModal, selectedEmployeeId]);

  const isProcessing = () => {
    if (
      selectedLocations?.length !==
        selectedEmployeeData?.connected?.location?.length ||
      selectedEmployeeData?.connected?.procedure?.includes(
        props.data.documentId
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="inherit-parent-height position-relative">
      <div className="inherit-parent-height padding-medium">
        {(!selectedEmployeeId || props.procedureType === "sensor") &&
          !isValidArray(
            isValidObject(props.sensors.data) &&
              Object.values(props.sensors.data).filter((data) =>
                data.linkedProcedures?.find(
                  (value) => value.procedureId === props.data?.documentId
                )
              )
          ) && (
            <>
              <HeaderCard
                title="ASSIGN PROCEDURES"
                description="Select an employee to assign the procedure"
                backArrowOnClick={props.backArrowOnClick}
                theme={props.theme}
              />
              <form
                data-cy="assign-procedure-screen"
                onChange={(event) => {
                  setSearchKey({
                    ...searchKey,
                    [props.procedureType === "employee"
                      ? "employee"
                      : "sensor"]: event.target.value
                  });
                }}
                className="remaining-height-for-search-sensors"
              >
                <div className="balance-height-for-form">
                  <InputBox
                    name="searchKey"
                    type="text"
                    removeResponsive={true}
                    label="SEARCH"
                    autoFocus={true}
                    placeholder="NAME / PHONE NUMBER"
                    className="padding-top-large  "
                    value={searchKey.employee}
                    fontColor={props.theme === "dark" ? "font-color-white" : ""}
                    labelFontColor={
                      props.theme === "dark" ? "font-color-white" : ""
                    }
                  />
                  <div className="remaining-height-for-search-results overflow-scroll margin-top-small hide-scroll-bar">
                    {(props.procedureType === "employee" ||
                      props.procedureType === "logistics") && (
                      <>
                        {props.employees.data &&
                          employeeSearchResults?.length > 0 &&
                          employeeSearchResults?.map((data, index) => (
                            <div
                              key={index}
                              className="padding-top-default padding-bottom-default inherit-parent-width"
                            >
                              <EmployeeCard
                                data-cy={`${data.employeeId}-card`}
                                data={data}
                                cardOnclick={() => {
                                  props.removeAlgoliaSearch();
                                  setSelectedEmployeeId(data.employeeId);
                                }}
                                theme={props.theme}
                              />
                            </div>
                          ))}
                      </>
                    )}

                    {props.procedureType === "sensor" && (
                      <>
                        {props.sensors.data &&
                          sensorSearchResults?.length > 0 &&
                          sensorSearchResults?.map((data, index) => (
                            <div
                              key={index}
                              className="padding-top-default padding-bottom-default"
                            >
                              <SensorCard
                                // selected={
                                //   data.documentId === selectedEmployeeId
                                // }
                                data-cy={`${data.documentId}-card`}
                                data={data}
                                cardOnclick={() => {
                                  setSelectedEmployeeId(data.documentId);
                                  setShowEditModal(true);
                                }}
                                theme={props.theme}
                              />
                            </div>
                          ))}
                      </>
                    )}

                    {searchKey.sensor?.length > 0 &&
                      (((props.procedureType === "employee" ||
                        props.procedureType === "logistics") &&
                        employeeSearchResults?.length === 0) ||
                        (props.procedureType === "sensor" &&
                          sensorSearchResults?.length === 0)) && (
                        <SearchFallBack type={props.procedureType} />
                      )}
                  </div>
                </div>
              </form>
            </>
          )}

        {(!selectedEmployeeId || props.procedureType === "sensor") &&
          isValidArray(
            Object.values(props.sensors.data).filter((data) =>
              data.linkedProcedures?.find(
                (value) => value.procedureId === props.data?.documentId
              )
            )
          ) && (
            <div className="inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-align-items-center">
              <SensorAssignedFallBack
                theme={props.theme}
                data={
                  Object.values(props.sensors.data).filter((data) =>
                    data.linkedProcedures?.find(
                      (value) => value.procedureId === props.data.documentId
                    )
                  )[0]
                }
                editOnClickButton={() => {
                  setSelectedEmployeeId(
                    Object.values(props.sensors.data).filter((data) =>
                      data.linkedProcedures?.find(
                        (value) => value.procedureId === props.data.documentId
                      )
                    )[0]?.documentId
                  );

                  setShowEditModal(true);
                }}
              />
            </div>
          )}

        {selectedEmployeeId &&
          !showLocations &&
          (props.procedureType === "employee" ||
            props.procedureType === "logistics") && (
            <>
              <HeaderCard
                title="VIEW PROFILE DETAILS"
                description="View the profile to assign the location"
                backArrowOnClick={() => {
                  setSelectedEmployeeId(null);
                }}
                theme={props.theme}
              />
              <div
                data-cy="view-profile-screen"
                className="padding-top-larger "
              >
                <div className=" padding-bottom-large border-bottom-quaternary">
                  <div className=" font-size-default ">PROFILE</div>

                  <div className="display-flex flex-align-items-center padding-top-medium">
                    <UserIcon
                      color={props.theme === "dark" ? "white" : "#404040"}
                    />

                    <div
                      style={{
                        width: "calc(100% - 25px)",
                        justifyContent: "end"
                      }}
                      className="text-overflow-ellipsis margin-left-default "
                    >
                      {selectedEmployeeData?.name}
                    </div>
                  </div>
                  <div className="display-flex flex-align-items-center padding-top-medium">
                    <PhoneNumberIcon
                      color={props.theme === "dark" ? "white" : "#404040"}
                    />

                    <div
                      style={{
                        width: "calc(100% - 25px)",
                        justifyContent: "end"
                      }}
                      className="text-overflow-ellipsis margin-left-default "
                    >
                      {selectedEmployeeData?.phoneNumber}
                    </div>
                  </div>
                </div>

                <div className="font-size-default padding-top-large">
                  LOCATIONS
                </div>
              </div>

              <form
                data-cy="view-location-screen"
                onChange={(event) => {
                  setSearchKey({
                    ...searchKey,
                    location: event.target.value
                  });
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  setIntervalModal({ show: true, type: null });
                }}
                style={{
                  height: "calc(100% - 268px)"
                }}
                className=" display-flex flex-direction-column flex-justify-content-space-between inherit-parent-height"
              >
                <div className="inherit-parent-height">
                  <InputBox
                    name="location"
                    type="text"
                    removeResponsive={true}
                    label="SEARCH"
                    placeholder="PCT / ADDRESS"
                    autoFocus={true}
                    className="padding-top-default"
                    value={searchKey.location}
                    fontColor="font-color-white"
                    labelFontColor="font-color-white"
                  />
                  <div className="display-flex flex-justify-content-space-between padding-bottom-default">
                    <div>
                      {(selectedLocations?.length !== 0 &&
                        selectedLocations?.length
                          ?.toString()
                          ?.padStart(2, "0")) ||
                        "00"}
                    </div>
                    <div
                      data-cy={
                        props.hits?.length !== 0 &&
                        selectedLocations?.length !== 0 &&
                        props.hits?.length === selectedLocations?.length
                          ? "remove-all-button"
                          : "select-all-button"
                      }
                      onClick={() => {
                        if (
                          props.hits?.length !== 0 &&
                          selectedLocations?.length !== 0 &&
                          props.hits?.length === selectedLocations?.length
                        ) {
                          setSelectedLocations([]);
                        } else {
                          const allLocations = props.hits.map((location) => ({
                            locationId: location.documentId,
                            procedureId: props.data.documentId
                          }));

                          const filteredLocations = allLocations.filter(
                            (location) =>
                              !selectedLocations.some(
                                (selectedLocation) =>
                                  selectedLocation.locationId ===
                                  location.locationId
                              )
                          );

                          setSelectedLocations([
                            ...selectedLocations,
                            ...filteredLocations
                          ]);
                        }
                      }}
                      className="font-color-white font-size-medium"
                    >
                      {props.hits?.length !== 0 &&
                      selectedLocations?.length !== 0 &&
                      props.hits?.length === selectedLocations?.length
                        ? "REMOVE ALL"
                        : "SELECT ALL"}
                    </div>
                  </div>

                  <div
                    style={{
                      height: "calc(100% - 60px)"
                    }}
                    className="overflow-auto hide-scrollbar"
                  >
                    {locationSearchResults?.length > 0 &&
                      locationSearchResults?.map((data, index) => (
                        <div key={index} className="padding-top-large">
                          <LocationCard
                            data-cy={`${data.documentId}-location`}
                            data={data}
                            selected={isValidObject(
                              selectedLocations?.find(
                                (value) => value.locationId === data?.documentId
                              )
                            )}
                            theme={props.theme}
                            cardOnclick={() => {
                              if (
                                isValidObject(
                                  selectedLocations?.find(
                                    (value) =>
                                      value.locationId === data?.documentId &&
                                      value.procedureId ===
                                        props.data.documentId
                                  )
                                )
                              ) {
                                setSelectedLocations(
                                  selectedLocations.filter(
                                    (value) =>
                                      value.locationId !== data?.documentId
                                  )
                                );
                              } else {
                                setSelectedLocations([
                                  ...selectedLocations,
                                  {
                                    locationId: data?.documentId,
                                    procedureId: props.data.documentId
                                  }
                                ]);
                              }
                            }}
                          />
                        </div>
                      ))}

                    {props.hits?.length === 0 && (
                      <SearchFallBack type="location" />
                    )}
                  </div>
                  <Button
                    className=" margin-top-medium"
                    text="Confirm"
                    data-cy={"add-location-button"}
                    loading={
                      isValidArray(selectedEmployeeData?.linkedProcedures)
                        ? selectedEmployeeData?.linkedProcedures?.every(
                            (data) => data.processing === false
                          )
                          ? false
                          : true
                        : false
                    }
                    disabled={confirmButtonDisabled}
                    onClick={(event) => {
                      event.preventDefault();
                      if (selectedLocations.length > 0) {
                        setIntervalModal({ show: true, type: null });
                      } else {
                        props.assignProcedure(selectedEmployeeId, "clearAll");
                      }
                    }}
                    type="button"
                    boxShadow={false}
                  />
                </div>
              </form>
            </>
          )}
      </div>

      <Modal
        show={showEditModal}
        canIgnore={true}
        onClose={() => {
          setShowEditModal(!showEditModal);
        }}
        borderRadius="false"
        width="inherit-parent-width"
        boxShadow="box-shadow-none"
        maxWidth="max-width-528px"
        height="height-fit-to-content"
      >
        <div
          data-cy="assign-confirm-modal"
          className={`${
            props.theme === "dark" ? "background-color-dark" : ""
          } padding-larger box-shadow-default font-family-gilroy-regular inherit-parent-height inherit-parent-width`}
        >
          <div className="display-flex flex-justify-content-space-between flex-align-items-center">
            <div style={{ width: "16px" }} />
            <div className="">EDIT SENSOR</div>
            <div
              onClick={() => {
                setShowEditModal(false);
              }}
              style={{ maxHeight: "16px" }}
              className="cursor-pointer"
            >
              <CloseIcon
                height="16"
                width="16"
                color={props.theme === "dark" ? "white" : "#404040"}
              />
            </div>
          </div>
          <div className="text-align-center padding-top-large">
            You can adjust the interval of the sensor or delete the sensor from
            this procedure.
          </div>
          <div>
            <InputBox
              name="intervalHour"
              type="number"
              autoFocus={true}
              removeResponsive={true}
              label="INTERVAL"
              className="padding-top-large"
              value={intervalTiming.interval}
              fontColor={props.theme === "dark" ? "font-color-white" : ""}
              labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
              onChange={(event) => {
                setIntervalTiming({
                  ...intervalTiming,
                  interval: /\d/.test(event.target.value)
                    ? parseInt(event.target.value)
                    : event.target.value
                });
              }}
              validation={(value) => {
                if (!value) {
                  setIsValidInterval({ ...isValidInterval, interval: false });
                  return {
                    status: true
                  };
                } else if (!(value <= 24)) {
                  setIsValidInterval({ ...isValidInterval, interval: false });
                  return {
                    status: false,
                    message: "Please enter a valid interval"
                  };
                } else {
                  setIsValidInterval({ ...isValidInterval, interval: true });
                  return {
                    status: true
                  };
                }
              }}
            />
          </div>
          <div className="padding-top-large display-flex">
            <Button
              text="Save"
              type="button"
              data-cy="save-button"
              onClick={() => {
                if (isValidInterval.interval) {
                  props.assignProcedure(selectedEmployeeId, [
                    {
                      procedureId: props.data.documentId,
                      interval: intervalTiming.interval
                    }
                  ]);
                  setShowEditModal(false);
                  setSelectedEmployeeId(null);
                  setIntervalTiming({
                    interval: "",
                    from: "",
                    period: "",
                    to: ""
                  });
                }
              }}
              disabled={
                intervalTiming.interval && isValidInterval.interval
                  ? false
                  : true
              }
              boxShadow={false}
            />
            {intervalTiming.interval && (
              <Button
                text="Delete"
                data-cy="assign-delete-button"
                variant="transparent"
                className={`  ${
                  props.theme === "dark"
                    ? "font-color-white"
                    : "font-color-secondary"
                } border-1px-e5e5e5 margin-left-large font-color-red`}
                type="button"
                onClick={() => {
                  props.deleteSensorProcedure(selectedEmployeeId, {
                    procedureId: props.data.documentId,
                    interval: intervalTiming.interval
                  });
                  setShowEditModal(!showEditModal);
                  setSelectedEmployeeId(null);
                  setIntervalTiming({
                    interval: "",
                    from: "",
                    period: "",
                    to: ""
                  });
                }}
                boxShadow={false}
              />
            )}
          </div>
        </div>
      </Modal>

      {/* Interval modal */}
      <Modal
        show={intervalModal.show}
        canIgnore={true}
        onClose={() => {
          setIntervalModal({ show: false, type: null });
        }}
        borderRadius="false"
        width="inherit-parent-width"
        boxShadow="box-shadow-none"
        maxWidth="max-width-528px"
        height="height-fit-to-content"
      >
        <div
          data-cy="add-interval-modal"
          className={`${
            props.theme === "dark" ? "background-color-dark" : ""
          } padding-larger box-shadow-default font-family-gilroy-regular  inherit-parent-height inherit-parent-width`}
        >
          <div className="display-flex flex-justify-content-space-between flex-align-items-center">
            <div style={{ width: "16px" }} />
            <div className="">PROCESS DETAILS</div>
            <div
              onClick={() => {
                setIntervalModal({ show: false, type: null });
              }}
              style={{ maxHeight: "16px" }}
              className="cursor-pointer"
            >
              <CloseIcon
                height="16"
                width="16"
                color={props.theme === "dark" ? "white" : "#404040"}
              />
            </div>
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              let editedLocations = [];
              for (let index = 0; index < selectedLocations?.length; index++) {
                editedLocations.push({
                  locationId: selectedLocations[index].locationId,
                  interval: intervalTiming.interval,
                  period: intervalTiming.period,
                  from: intervalTiming.from,
                  to: intervalTiming.to,
                  procedureId: props.data.documentId,
                  processing: isProcessing()
                });
              }
              props.assignProcedure(selectedEmployeeId, editedLocations, {
                from: intervalTiming.from,
                to: intervalTiming.to
              });

              setIntervalTiming({
                interval: "",
                period: "",
                from: "",
                to: ""
              });
              setIntervalModal({ show: false, type: null });
            }}
            className="display-flex flex-direction-column flex-justify-content-space-between"
          >
            <div>
              <InputBox
                name="interval"
                type="number"
                data-cy="intervalHour-input-box"
                autoFocus={true}
                onChange={(event) => {
                  setIntervalTiming({
                    ...intervalTiming,
                    interval: /\d/.test(event.target.value)
                      ? parseInt(event.target.value)
                      : event.target.value
                  });
                }}
                removeResponsive={true}
                label="INTERVAL"
                className="padding-top-large"
                fontColor={props.theme === "dark" ? "font-color-white" : ""}
                labelFontColor={
                  props.theme === "dark" ? "font-color-white" : ""
                }
                value={
                  !isNaN(intervalTiming.interval) ? intervalTiming.interval : ""
                }
                validation={(value) => {
                  if (!value) {
                    setIsValidInterval({ ...isValidInterval, interval: false });

                    return {
                      status: true
                    };
                  }
                  if (!(value <= 24)) {
                    setIsValidInterval({ ...isValidInterval, interval: false });

                    return {
                      status: false,
                      message: "Please enter a valid interval"
                    };
                  } else {
                    setIsValidInterval({ ...isValidInterval, interval: true });

                    return {
                      status: true
                    };
                  }
                }}
              />
              <NativeSelect
                name="period"
                removeResponsive={true}
                label="PERIOD"
                selectClassName="text-transform-capitalize"
                options={["", "Hours", "Days", "Months"]}
                defaultValue={
                  intervalTiming.period
                    ? capitalizeText(intervalTiming.period)
                    : intervalTiming.period
                }
                onChange={(event) => {
                  setIntervalTiming({
                    ...intervalTiming,
                    period: event.target.value?.toLowerCase()
                  });
                }}
                fontColor={
                  props.theme === "dark"
                    ? "font-color-white"
                    : "font-color-secondary"
                }
              />
              <div className="display-flex inherit-parent-width">
                <div className="padding-right-default inherit-parent-width">
                  <InputBox
                    removeResponsive={true}
                    label="From"
                    size="small"
                    name="time"
                    step={(30 * 60).toString()}
                    type="time"
                    fontColor={props.theme === "dark" ? "font-color-black" : ""}
                    style={
                      props.theme === "dark" ? { filter: "invert(1)" } : {}
                    }
                    labelFontColor={
                      props.theme === "dark" ? "font-color-white" : ""
                    }
                    validation={(value) => {
                      if (!value) {
                        setIsValidInterval({ ...isValidInterval, from: false });

                        return {
                          status: true
                        };
                      }

                      if (intervalTiming.to) {
                        if (value === intervalTiming.to) {
                          setIsValidInterval({
                            ...isValidInterval,
                            from: false
                          });

                          return {
                            status: false,
                            message: "Time should not be same"
                          };
                        } else if (
                          value.split(":")[1] !==
                          intervalTiming.to.split(":")[1]
                        ) {
                          setIsValidInterval({
                            ...isValidInterval,
                            from: false
                          });

                          return {
                            status: false,
                            message: "Minutes should be same"
                          };
                        }
                      }

                      if (
                        (value.split(":")[1] === "00" ||
                          value.split(":")[1] === "30") === false
                      ) {
                        setIsValidInterval({ ...isValidInterval, from: false });

                        return {
                          status: false,
                          message: "Time should be whole"
                        };
                      } else {
                        setIsValidInterval({ ...isValidInterval, from: true });

                        return {
                          status: true
                        };
                      }
                    }}
                    data-cy="from-time"
                    className=""
                    value={intervalTiming?.from}
                    onChange={(event) => {
                      setIntervalTiming({
                        ...intervalTiming,
                        from: event.target.value
                      });
                    }}
                    required={true}
                  />
                </div>
                <div className="padding-left-default inherit-parent-width">
                  <InputBox
                    removeResponsive={true}
                    label="To"
                    name="time"
                    size="small"
                    type="time"
                    step={(30 * 60).toString()}
                    data-cy="to-time"
                    fontColor={props.theme === "dark" ? "font-color-black" : ""}
                    style={
                      props.theme === "dark" ? { filter: "invert(1)" } : {}
                    }
                    labelFontColor={
                      props.theme === "dark" ? "font-color-white" : ""
                    }
                    validation={(value) => {
                      if (!value) {
                        setIsValidInterval({ ...isValidInterval, to: false });

                        return {
                          status: true
                        };
                      }

                      if (intervalTiming.from) {
                        if (value === intervalTiming.from) {
                          setIsValidInterval({ ...isValidInterval, to: false });

                          return {
                            status: false,
                            message: "Time should not be same"
                          };
                        } else if (
                          value.split(":")[1] !==
                          intervalTiming.from.split(":")[1]
                        ) {
                          setIsValidInterval({ ...isValidInterval, to: false });

                          return {
                            status: false,
                            message: "Minutes should be same"
                          };
                        } else if (
                          value.split(":")[0] <
                          intervalTiming.from.split(":")[0]
                        ) {
                          setIsValidInterval({ ...isValidInterval, to: false });

                          return {
                            status: false,
                            message: "Time should not less than time"
                          };
                        }
                      }
                      if (
                        (value.split(":")[1] === "00" ||
                          value.split(":")[1] === "30") === false
                      ) {
                        setIsValidInterval({ ...isValidInterval, to: false });

                        return {
                          status: false,
                          message: "Time should be whole"
                        };
                      } else {
                        setIsValidInterval({ ...isValidInterval, to: true });

                        return {
                          status: true
                        };
                      }
                    }}
                    className=""
                    value={intervalTiming?.to}
                    onChange={(event) => {
                      setIntervalTiming({
                        ...intervalTiming,
                        to: event.target.value
                      });
                    }}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="padding-top-large display-flex">
              <Button
                text="Save"
                data-cy="save-button"
                type="submit"
                disabled={
                  intervalTiming.interval &&
                  intervalTiming.period &&
                  intervalTiming.from &&
                  intervalTiming.to &&
                  isValidInterval.from &&
                  isValidInterval.to &&
                  isValidInterval.interval
                    ? false
                    : true
                }
                boxShadow={false}
              />

              <Button
                text="Cancel"
                variant="transparent"
                className={`  ${
                  props.theme === "dark"
                    ? "font-color-white"
                    : "font-color-secondary"
                } border-1px-e5e5e5 margin-left-large`}
                type="button"
                onClick={() => {
                  setIntervalModal({ show: false, type: null });
                }}
                boxShadow={false}
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

const SearchFallBack = (props) => {
  return (
    <>
      <div className="inherit-parent-height inherit-parent-width display-flex flex-direction-column flex-justify-content-center flex-align-items-center padding-larger">
        <img
          src={SearchFallBackIcon}
          alt="fallback "
          className=""
          width="99px"
          height="106px"
        />
        <div className="font-size-large  padding-top-larger">
          No matching results
        </div>
        <div className="text-align-center padding-top-large font-weight-300 font-size-default ">
          {`There wasn’t any ${props.type} matching to your search result`}
        </div>
      </div>
    </>
  );
};

function SensorAssignedFallBack(props) {
  return (
    <div
      className="inherit-parent-height display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      data-cy="assigned-sensor-fallback"
    >
      <img
        alt="fallBack"
        src={sensorAssignedFallBackImage}
        className="height-153px"
      />
      <div className=" padding-top-large">SENSOR ASSIGNED</div>
      <div className="text-align-center padding-medium">
        Selected procedure already assigned with a {props.data.name} with{" "}
        {props.data.toleranceLevel} tolerance level
      </div>

      <div
        className="display-flex flex-align-items-center flex-justify-content-center border-tertiaryColor width-200px border-radius-default padding-top-default padding-bottom-default"
        data-cy="assigned-sensor-edit-button"
        onClick={props.editOnClickButton}
      >
        <ProcedureEditIcon
          height={14}
          color={props.theme === "dark" ? "white" : "#404040"}
        />
        <div className="padding-left-default">Edit</div>
      </div>
    </div>
  );
}

function capitalizeText(text) {
  if (text?.length === 0) {
    return "";
  }
  return text?.charAt(0).toUpperCase() + text?.slice(1);
}
